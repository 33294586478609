


















































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Location from '@/models/Location';
import { LMap, LTileLayer, LMarker, LCircle } from 'vue2-leaflet';
import { icon } from 'leaflet';
import mapIcon from '@/assets/images/map/map-marker-location.png';
import Customer from '@/models/Customer';
import {Permission} from '@/misc/enums/permission.enum';

const CustomerStore = namespace('customer');

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LCircle,
    },
})
export default class LocationManageSpecificDataComponent extends Vue {

    private MAP_ICON_SCALE_QUOTIENT: number = 2;
    private MAP_RADIUS_MAX: number = 1000;
    private MAP_RADIUS_MIN: number = 20;
    private MAP_RADIUS_DEFAULT: number = 20;
    private MAP_RADIUS_DEFAULT_ZOOM: number = 14;

    // variable for holding our Interval, for retrieving the location from the api
    private geoInterval: any = undefined;
    // Time to poll in ms
    private GEOCODE_POLL_TIME_IN_MS: number = 1000;

    // Values for the status selection
    private objectStatus: Array<{ status: string, value: boolean }> = [];

    private isChanging: boolean = false;

    /**
     * Map config values
     */
    public mapConfig: any = {
        url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
        zoom: this.MAP_RADIUS_DEFAULT_ZOOM,
        center: [0, 0],
        markerCoords: [0, 0],
        radiusCircle: {
            center: [0, 0],
            radius: this.MAP_RADIUS_DEFAULT,
            color: this.radiusColor,
            opacity: .7,
            weight: 1,
            fillColor: this.radiusBackground,
            fillOpacity: .2,
        },
        icon: icon({
            iconUrl: mapIcon,
            // 52,90 are the original dimension
            iconSize: [75 / this.MAP_ICON_SCALE_QUOTIENT, 75 / this.MAP_ICON_SCALE_QUOTIENT],
            iconAnchor: [37.5 / this.MAP_ICON_SCALE_QUOTIENT, 75 / this.MAP_ICON_SCALE_QUOTIENT],
        }),
    };

    @CustomerStore.Mutation('storeLocation')
    public storeLocationMutation!: (location: Location | undefined) => void;
    @CustomerStore.Action('loadLocationAction')
    private loadLocationAction!: (payload: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
    @CustomerStore.Action('editLocationAction')
    private editLocationAction!: (payload: { location: Location, shouldBeStored: boolean }) => Promise<Location>;
    @CustomerStore.Getter('location')
    private _location!: Location;
    @CustomerStore.Getter('customer')
    private customer!: Customer;

    /**
     * The Map cannot be shown correctly
     */
    public mapError: boolean = false;

    get location(): Location {
        return this._location;
    }

    get radiusColor(): string {
        return this.$colorHandler.getThemeColor('map-radius-border');
    }

    get radiusBackground(): string {
        return this.$colorHandler.getThemeColor('map-radius-background');
    }

    /**
     * Flag for displaying address linking
     */
    get isCustomerAddressLinked(): boolean {
        return this.customer.address!.id === this.location.address!.id;
    }

    /**
     * Flag for displaying customer person linking
     */
    get isCustomerContactPersonLinked(): boolean {
        return this.customer.contactPerson!.id === this.location.contactPerson!.id;
    }

    public destroyed() {
      // On Destroy, clear the geo interval if it was set
      if (this.geoInterval) {
        clearInterval(this.geoInterval);
      }
    }

    @Watch('location', {immediate: true})
    public onLocationChange() {
        // We switched locations, clear our current interval
        // Clear Intervals, so it doesn't suck up memory and the execution stops
        if (this.geoInterval) {
          clearInterval(this.geoInterval);
        }

        if (this.location) {
            // Check for Geo Positions inside the address object
            const geoPosition = this.location.derivedAddress!.geoPosition;
            const geoPending = this.location.derivedAddress!.geocodePending;

            if (geoPosition) {
                this.mapConfig.center = geoPosition;
                this.mapConfig.markerCoords = geoPosition;
                this.mapConfig.radiusCircle.center = geoPosition;
                this.mapConfig.radiusCircle.radius = this.location.radius;
                this.mapError = false;
            } else {
                this.mapError = true;
                if (geoPending) {
                  // Start Interval to ask the api if we already have a Geo-position
                  this.geoInterval = setInterval(() => this.onGeoPositionAvailable(this.location),
                      this.GEOCODE_POLL_TIME_IN_MS);
                }
            }
        }
    }

    private async onGeoPositionAvailable(currentLocation: Location) {
      // Get updated Location from API, don't store for now. so no update of the page will be triggered
      const loc: Location = await this.loadLocationAction({ locationId: currentLocation.id!, shouldBeStored: false });

      // Check if our geocode is not pending anymore. && loc.address?.geoPosition
      if (!loc.derivedAddress!.geocodePending) {
        // No Geo Position available set map to error
        if (!loc.derivedAddress?.geoPosition) {
          this.mapError = true;
        }

        // Turn of any map errors
        this.mapError = false;

        // Save this Location inside our CustomerStore
        this.storeLocationMutation(loc);
        // Remove interval for current item
        clearInterval(this.geoInterval);
      }
    }

    public async updateLocation() {
      if (!this.isChanging) {
        this.isChanging = true;
        try {
          await this.editLocationAction({
            location: this.location,
            shouldBeStored: false,
          });
          this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.SUCCESS');
        } catch (e) {
          this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.ERROR');
        } finally {
          this.isChanging = false;
        }
      }
    }

    public created() {
        this.objectStatus = [
            {status: this.$t('GENERAL.ACTIVE').toString(), value: true},
            {status: this.$t('GENERAL.INACTIVE').toString(), value: false},
        ];
    }
  private updateRadius(e: any) {
    this.mapConfig.radiusCircle.radius = e;
    this.updateLocation();
  }
}
